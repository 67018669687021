const React = require('react');

const { useEffect } = React;

const PropTypes = require('prop-types');
const { Head } = require('nordic/head');

const MeliGA = require('nordic/analytics/meli-ga');
const { MelidataTrack } = require('nordic/melidata/melidata-track');
const { Style } = require('nordic/style');
const { useI18n } = require('nordic/i18n');
const Appearance = require('@mshops-web-core/appearance').default;
const { Icons } = require('@polycard/icons');
const RedirectApp = require('redirect-app-web');

const HomeLayout = require('./HomeLayout');
const ZipcodeSubscription = require('./ZipcodeSubscription');
const RenderTree = require('../../../appearance/renderTree').default;

const CartSubscription = require('./CartSubscription');

const { RenderProvider } = require('../../home/context');
const { CartProvider } = require('../context/cart');
const { FreeShippingProvider } = require('../context/freeShipping');

const { COMPONENTS_STATICS_TOP } = require('../../../utils/constants/common');
const { POLYCARD_ICONS } = require('../../../utils/constants/shop/polycardIcons');

const { useTitle } = require('../../../hooks/useTitle');
const nonEditableComponentList = require('./nonEditableComponents');
const { CUSTOM_CONTENT_PAGE, NAVIGATION } = require('../../../utils/constants/sections');
const { SELLER_TYPES_VIS } = require('../../../utils/constants/shop/sellerTypesVis');
const { default: BottomSheetProvider } = require('../../../appearance/bottomSheetMenu/context/BottomSheetProvider');
const { getTracksCcp, getRepurchaseTracks, getCarouselsMelidata } = require('../../../utils/eshopTracks');
const { SnackbarProvider } = require('../../../appearance/snackbar/context/SnackbarProvider');
const { isOffersCarousel } = require('../../../appearance/productsCarouselEshops/utils');

function MarketplaceHomeView({
  site,
  siteId,
  lowEnd,
  deviceType,
  osName,
  shopModel,
  config,
  section,
  allowMetrics,
  siteKey,
  apiBasePath,
  hideModalSubscription,
  isHubPreview,
  uuidVersion,
  buyerId,
  nonce,
  isWebview,
  zipCode,
  userSiteId = '',
  urlEncode,
  freeShippingBar,
  addedItems,
  d2id,
  openInApp,
  maskUrl,
  features,
  clientExperiments,
  device: reqDevice,
  initialSlideCouponCarousel = 0,
}) {
  const { i18n } = useI18n();
  // eslint-disable-next-line
  const { appearance, fiscal_data: fiscalData, shop, storefront, contact } = shopModel;
  const { currency, title } = appearance.properties;
  const { restClient } = config;
  const ShopTypeId = storefront?.id ?? shop.id;

  useTitle(title);
  const ownerId = storefront?.owner_id || null;
  const labelURL = storefront?.label_url;
  const storefrontType = storefront?.type;
  const homeURL = storefront?.home_url;

  useEffect(() => {
    if (maskUrl?.fromMaskUrl) {
      const storeSubdomain = homeURL.split(storefront?.domain)?.[1];
      window.history.pushState(null, null, storeSubdomain + maskUrl?.maskUrlParams);
    }
    if (deviceType === 'mobile' && !window.MobileWebKit && storefrontType === 'affiliate') {
      const deeplink = `meli://webview/?webkit-engine=2&url=${urlEncode}&hides_bottom_bar=true&toolbar_elevation=none&search_mode=collapsed&cart_mode=default`;
      window.location = deeplink;
    }
  }, []);

  const appearanceService = new Appearance(appearance);
  const layoutTheme = appearanceService.getComponentProperty('Document', 'theme');
  const metaTags = appearanceService.getComponentProperty('Document', 'meta_tags');

  // Force tab title
  if (typeof window !== 'undefined' && window.document) {
    window.document.title = title;
  }

  if (typeof window !== 'undefined' && window.MobileWebKit) {
    const urlParams = new URLSearchParams(window.location.search);
    const hasDraftMode = urlParams.has('draft_mode');

    if (hasDraftMode) {
      window.MobileWebKit?.navigation.subscribeToBackAction(() => {
        window.MobileWebKit.page.navigation.push('meli://home', {
          replace: true,
        });
      });
    }
  }

  const page = section.toUpperCase();
  const customDimensions = { collectorId: ShopTypeId };

  // melidata metrics
  const sliders = appearance.children
    .find(child => child.id === 'layout')
    .children.filter(child => child.id.includes('slider'));

  const couponsCarousel = appearance?.children
    .find(child => child?.id === 'layout')
    .children?.find(child => child?.id === 'coupon-carousel');
  const { visible: couponVisible, coupons } = couponsCarousel?.properties || {};
  const couponQuantity = coupons?.length ?? 0;

  const offersCarousel = appearance?.children
    .find(child => child?.id === 'layout')
    .children?.find(child => isOffersCarousel(child?.id));
  const { tabs: tabsOffers, values: valuesOffers } = offersCarousel?.properties || {};
  const qtyOffersCarousel = (tabsOffers && tabsOffers[0]?.polycards?.length) || 0;
  const showOffersCarousel = qtyOffersCarousel > 3;
  const { carouselsTracks, carouselsExperiments } = getCarouselsMelidata(appearanceService);

  const shopType = shopModel?.storefront?.type;
  const melidataMetrics = {
    shop_id: ShopTypeId,
    shop_type: shopType,
    shop_name: shopModel?.storefront?.name || shopModel?.shop?.name,
    official_store_id: parseInt(shopModel?.storefront?.filters?.official_store, 10) || -1,
    followers: parseInt(shopModel?.appearance?.children[0]?.children[0]?.properties?.followers?.text?.replace(' seguidores', ''), 10) || -1,
    item_id: shopModel?.appearance?.properties?.metrics?.item_id || 'NONE',
    empty_state: shopModel?.appearance?.properties?.style_config?.section === 'empty_state',
    zip_code: zipCode || 'NONE',
    matt_tool: shopModel?.appearance?.properties?.metrics?.matt_tool || 'NONE',
    catalog_product_id: shopModel?.appearance?.properties?.metrics?.catalog_product_id || 'NONE',
    status: shopModel?.appearance?.properties?.metrics?.status || 'NONE',
    category_id: shopModel?.appearance?.properties?.metrics?.category_id || 'NONE',
    tracking_id: shopModel?.appearance?.properties?.metrics?.tracking_id || 'NONE',
    storefront_type: shopModel?.storefront?.tags?.find(tag => SELLER_TYPES_VIS.includes(tag)) || 'normal',
    seller_id: ownerId,
    has_slider_v2: sliders.some(slider => slider.properties.version === 'v2'),
    has_slider_v1: sliders.some(slider => slider.properties.version === 'v1'),
    ...getRepurchaseTracks(appearanceService),
    ...carouselsTracks,
    ...shopModel?.appearance?.properties?.metrics,
    buyer_id: buyerId?.toString() || '',
    has_coupons_carousel: (couponVisible && couponQuantity > 0) || false,
    qty_coupons_carousel: (couponVisible && couponQuantity) || 0,
    has_offers_carousel: showOffersCarousel,
    qty_offers_carousel: (showOffersCarousel && qtyOffersCarousel) || 0,
    type_offer: showOffersCarousel ? (valuesOffers && valuesOffers[0]?.promotion_type) : 'not_apply',
  };

  const tracks = getTracksCcp(appearanceService);

  const eshopPath = '/eshops';
  const reportPageTracks = {
    melidata_track: {
      type: 'event',
      event_data: {
        client: 'home',
        component_id: 'report_page',
        shop_id: ShopTypeId,
        shop_name: storefront?.name,
        shop_type: shopType,
        owner_id: ownerId?.toString() || 'NONE',
      },
    },
    paths: {
      report: `${eshopPath}/report`,
      share: `${eshopPath}/share`,
    },
  };

  const commonTrackData = {
    buyer_id: buyerId?.toString?.() ?? '',
    storefront_id: ShopTypeId,
    storefront_name: storefront?.name,
    storefront_type: shopType,
  };

  const marketplaceInfo = {
    ownerId,
    buyerId,
    userZipCode: zipCode,
    userSiteId,
    shopType: shopModel.storefront?.type,
    labelURL,
    isWebview: isWebview ?? false,
    siteId,
    d2id,
    homeURL,
    trackingId: shopModel?.appearance?.properties?.metrics?.tracking_id || 'NONE',
    trackExperiments: shopModel?.appearance?.properties?.experiments ?? {},
  };

  const initialAppearance = new Appearance(appearance);

  const numberStaticComponentsTop = COMPONENTS_STATICS_TOP.reduce((acc, componentId) => {
    const component = initialAppearance.getComponentById(componentId);

    if (component) {
      return acc + 1;
    }

    return acc;
  }, 0);

  const renderProviderInitialValue = {
    appearance: initialAppearance,
    initial: initialAppearance,
    device: deviceType,
    osName,
    apiBasePath,
    draftMode: !!(uuidVersion),
    uuidVersion,
    marketplaceInfo,
    shopType,
    isEshops: true,
    numberStaticComponentsTop,
    reportPageTracks,
    maskUrl,
    currency,
    siteId,
    lowEnd,
    nonce,
    siteKey,
    restClient,
    fiscalData,
    contact,
    socialNetworks: shop?.social_networks,
    theme: layoutTheme,
    shopModel,
    hideModalSubscription,
    isHubPreview,
    urlEncode,
    features,
    initialSlideCouponCarousel,
    allowMetrics,
    commonTrackData,
    reqDevice,
  };

  const isSupermakert = ['super', 'super_partner'].includes(shopType);
  const isCustomContentPage = section === CUSTOM_CONTENT_PAGE;
  const isNavigation = section === NAVIGATION;
  let eventData;
  if (isCustomContentPage) {
    eventData = {
      shop_id: ShopTypeId,
      shop_name: storefront?.name,
      shop_type: shopType,
      ...tracks?.melidata_track?.event_data,
      zip_code: zipCode || 'NONE',
      has_slider_v2: sliders.some(slider => slider.properties.version === 'v2'),
      has_slider_v1: sliders.some(slider => slider.properties.version === 'v1'),
      ...carouselsTracks,
    };
  } else if (isNavigation) {
    eventData = {
      shop_type: shopType,
      owner_id: ownerId?.toString() || 'NONE',
      buyer_id: buyerId?.toString() || '',
      official_store_id: parseInt(shopModel?.storefront?.filters?.official_store, 10) || -1,
      shop_id: ShopTypeId,
      shop_name: shopModel?.storefront?.name || shopModel?.shop?.name,
      tracking_id: shopModel?.appearance?.properties?.metrics?.tracking_id || 'NONE',
    };
  } else {
    eventData = { ...melidataMetrics };
  }
  const melidataPath = {
    home: '/eshops/home',
    navigation: '/eshops/menu',
    custom_content_page: '/eshops/home/custom_content_page',
  };
  let experiments = { ...marketplaceInfo.trackExperiments, ...carouselsExperiments, ...clientExperiments };
  if (storefrontType === 'affiliate') {
    experiments = { ...experiments, 'perfil/social': 78798 };
  }

  return (
    <>
      {storefrontType === 'affiliate' && openInApp?.shouldRedirect && <RedirectApp {...openInApp} />}
      {(allowMetrics)
        && [
          <MeliGA
            siteId={siteId}
            platform={site}
            section="ESHOPS"
            business="MARKETPLACE"
            page={`/${page}/`}
            dimensions={customDimensions}
          />,
          <MelidataTrack
            path={melidataPath[section] || melidataPath.home}
            event_data={{ ...eventData }}
            experiments={experiments}
          />,
        ]
      }

      <Head>
        <title>{title}</title>
        {(metaTags && metaTags.length)
          ? metaTags.map((meta) => {
            if (meta.type?.startsWith('og:')) {
              return <meta property={meta.type} content={meta.content} />;
            }
            return <meta name={meta.type} content={meta.content} />;
          })
          : null
        }
      </Head>
      <Style href={`home-eshops-${deviceType}.css`} />
      <Style
        href={`${layoutTheme}/home-${layoutTheme}-${deviceType}.css`}
        preload={deviceType !== 'mobile'}
        critical={deviceType === 'mobile'}
      />

      <RenderProvider value={renderProviderInitialValue}>
        <CartProvider value={{ apiBasePath, addedItems, marketplaceInfo, osName }}>
          <FreeShippingProvider value={{ apiBasePath, freeShippingBar }}>
            <BottomSheetProvider>
              <SnackbarProvider>
                <HomeLayout
                  section={section}
                  shopName={shopModel?.storefront?.name || shopModel?.shop?.name}
                  osName={osName}
                  isWebview={isWebview}
                >
                  <RenderTree componentsList={nonEditableComponentList} i18n={i18n} />
                </HomeLayout>
                <Icons icons={POLYCARD_ICONS} serverSideRenderIcons />
                {isWebview && isSupermakert && <CartSubscription />}
                {isWebview && <ZipcodeSubscription storefrontType={storefrontType} />}
              </SnackbarProvider>
            </BottomSheetProvider>
          </FreeShippingProvider>
        </CartProvider>
      </RenderProvider>
    </>
  );
}

MarketplaceHomeView.propTypes = {
  site: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  deviceType: PropTypes.string.isRequired,
  osName: PropTypes.string,
  company: PropTypes.string.isRequired,
  shopModel: PropTypes.shape(),
  config: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
  section: PropTypes.string.isRequired,
  allowMetrics: PropTypes.bool.isRequired,
  siteKey: PropTypes.string.isRequired,
  apiBasePath: PropTypes.string,
  hideModalSubscription: PropTypes.bool.isRequired,
  isHubPreview: PropTypes.bool.isRequired,
  manifest: PropTypes.shape(),
  assetsPrefix: PropTypes.string,
  uuidVersion: PropTypes.string,
  nonce: PropTypes.string,
  zipCode: PropTypes.string,
  urlEncode: PropTypes.string.isRequired,
  freeShippingBar: PropTypes.shape(),
  addedItems: PropTypes.arrayOf(PropTypes.shape()),
  openInApp: PropTypes.shape().isRequired,
  maskUrl: PropTypes.shape({
    fromMaskUrl: PropTypes.bool,
    maskUrlParams: PropTypes.string,
  }),
  initialSlideCouponCarousel: PropTypes.number,
  userSiteId: PropTypes.string,
  experimentCarrouselCouponsCalledService: PropTypes.bool.isRequired,
  device: PropTypes.string.isRequired,
};

MarketplaceHomeView.defaultProps = {
  site: 'ML',
  shopModel: {
    appearance: {
      properties: {},
    },
    storefront: {
      tags: [],
    },
  },
  editable: false,
  manifest: {},
  assetsPrefix: '',
  uuidVersion: '',
  apiBasePath: '/api',
  maskUrl: {
    fromMaskUrl: false,
    maskUrlParams: '',
  },
};

module.exports = MarketplaceHomeView;
