const ClientService = require('../../../../../services/client');
const { SUPER } = require('../../../../utils/constants/sections');
const { CART_BY_SHOP_TYPE, ITEM_STOCK } = require('./constants');

const types = require('./types');

module.exports = class CartActions {
  SHOPS_TYPE = 'mshops';

  constructor(dispatcher, state) {
    this.dispatcher = dispatcher;
    this.restClient = new ClientService(state.apiBasePath);
  }

  static initialize(dispatcher, state) {
    if (!CartActions.singleton) {
      CartActions.singleton = new CartActions(dispatcher, state);
    }

    return CartActions.singleton;
  }

  setAddedItems(items) {
    this.dispatcher({
      type: types.SET_ADDED_ITEMS,
      payload: { items },
    });
  }

  getCartItems(shopType) {
    this.dispatcher({
      type: types.SET_CART_FETCHING,
      payload: true,
    });

    const cartPaths = CART_BY_SHOP_TYPE[shopType] || CART_BY_SHOP_TYPE.eshop;
    const requestMethod = shopType === this.SHOPS_TYPE ? 'post' : 'get';

    return this.restClient[requestMethod](
      cartPaths.getCartItems.path,
      { params: { type: shopType } },
      6000,
    ).then(({ data: addedItems }) => {
      this.setAddedItems(addedItems);
      if (window.CartBadge) {
        const cartQuantity = addedItems.reduce((acc, item) => acc + item.quantity, 0);
        window.CartBadge.setBadgeItems(cartQuantity);
      }
    }).finally(() => {
      this.dispatcher({
        type: types.SET_CART_FETCHING,
        payload: false,
      });
    });
  }

  addToCart(id, quantity, variation, userZipCode, shopType) {
    this.dispatcher({
      type: types.SET_CART_FETCHING,
      payload: true,
    });

    const cartPaths = CART_BY_SHOP_TYPE[shopType] || CART_BY_SHOP_TYPE.eshop;

    return this.restClient.post(
      cartPaths.addToCart.path,
      {
        data: { id, quantity, userZipCode, shopType, variation },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      },
    ).then(response => {
      this.dispatcher({
        type: types.CART_ADD_ITEM_SUCCESS,
        payload: { id, quantity },
      });
      return response;
    }).finally(() => {
      this.dispatcher({
        type: types.SET_CART_FETCHING,
        payload: false,
      });
    });
  }

  updateCartItem(id, quantity, variation, userZipCode, shopType) {
    this.dispatcher({
      type: types.SET_CART_FETCHING,
      payload: true,
    });

    const cartPaths = CART_BY_SHOP_TYPE[shopType] || CART_BY_SHOP_TYPE.eshop;
    const requestMethod = shopType === this.SHOPS_TYPE ? 'post' : 'put';

    return this.restClient[requestMethod](
      cartPaths.updateCartItem.path,
      {
        data: { id, quantity, userZipCode, shopType, variation },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      },
    ).then(response => {
      this.dispatcher({
        type: types.CART_UPDATE_ITEM_SUCCESS,
        payload: { id, quantity },
      });
      return response;
    }).finally(() => {
      this.dispatcher({
        type: types.SET_CART_FETCHING,
        payload: false,
      });
    });
  }

  deleteCartItem(id, variation, userZipCode, shopType) {
    this.dispatcher({
      type: types.SET_CART_FETCHING,
      payload: true,
    });

    const cartPaths = CART_BY_SHOP_TYPE[shopType] || CART_BY_SHOP_TYPE.eshop;
    const requestMethod = shopType === this.SHOPS_TYPE ? 'post' : 'delete';

    return this.restClient[requestMethod](
      cartPaths.deleteCartItem.path,
      {
        data: { id, userZipCode, shopType, variation },
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      },
    ).then(response => {
      this.dispatcher({
        type: types.CART_DELETE_ITEM_SUCCESS,
        payload: { id },
      });
      return response;
    }).finally(() => {
      this.dispatcher({
        type: types.SET_CART_FETCHING,
        payload: false,
      });
    });
  }

  updateItemStock(id, userProductId, userZipCode, shopType, availableQuantity) {
    if (shopType !== SUPER) return availableQuantity;

    const getItemStockPath = ITEM_STOCK.getItemStock.path;
    return this.restClient.post(
      getItemStockPath,
      {
        data: { userProductId, userZipCode, availableQuantity },
      },
    ).then(response => {
      this.dispatcher({
        type: types.CART_UPDATE_ITEM_STOCK_SUCCESS,
        payload: { id, geolocatedQuantity: response.data },
      });

      return response.data;
    });
  }
};
