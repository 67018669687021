const React = require('react');
const CategorySuper = require('@mshops-components-library/category-super').default;
const { CategoryLabelImage } = require('@mshops-components-library/categories-gallery');

const { useRenderContext } = require('../../pages/home/context');
const { WITH_CATEGORY_SUPER } = require('../../utils/constants/shop/types');

const CategoryItem = props => {
  const { shopType, appearance, isEshops } = useRenderContext();

  const hasRepurchase = appearance.getComponentByName('Repurchase');

  const categorySuperLayout = hasRepurchase ? 'rectangle' : 'square';
  return WITH_CATEGORY_SUPER.includes(shopType) ? (
    <CategorySuper layout={categorySuperLayout} {...props} />
  ) : (
    <CategoryLabelImage isEshops={isEshops} {...props} />
  );
};

module.exports = CategoryItem;
