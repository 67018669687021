const { getCollections } = require('./collections');
const { trackEvent } = require('./tracking');

const eshopReportTracks = ({ melidata_track: melidataTrack, type, paths }) => ({
  melidata_event: {
    type,
    path: paths?.report,
    event_data: melidataTrack?.event_data,
  },
});

const eshopShareTracks = (tracks, socialMedia) => {
  const { melidata_track: melidataTrack, type, paths } = tracks;
  return {
    melidata_event: {
      type,
      path: paths?.share,
      event_data: { ...melidataTrack.event_data, share_media: socialMedia },
    },
  };
};

const sendTracksTabs = (tracks, nameL2, positionL2) => {
  if (tracks) {
    const { melidata_track: originalMelidataTracks } = tracks;

    const melidataTracks = { ...originalMelidataTracks };

    melidataTracks.path = '/eshops/home/custom_content_page/tab';
    melidataTracks.event_data = {
      ...melidataTracks.event_data,
      node_name_l2: nameL2,
      node_position_l2: positionL2,
    };

    delete melidataTracks.event_data.node_count_l1;

    trackEvent({ melidata_event: melidataTracks });
  }
};

const getTracksCcp = (appearance) => {
  const dynamicCarousels = appearance.getComponentByName('DynamicCarousels');

  if (dynamicCarousels) {
    const { tabs } = dynamicCarousels.properties;

    if (tabs.length > 0) {
      return tabs[0].tracks;
    }
  }
  return null;
};

const getRepurchaseTracks = (appearance) => {
  const repurchase = appearance.getComponentByName('Repurchase');

  return { has_repurchase: !!repurchase };
};

/**
 * @param {Array} collections
 * @returns an array with the carousels float highlights tracks
 */
const getCarouselsFloatHighlightTracks = (collections) => {
  const carouselTracks = collections.map((carousel) => {
    const track = carousel.properties.tracks?.melidata_track;

    // Check if the 'track' is an array and has more than one element,
    // If so, the second element contains the carousel float highlights.
    if (track && Array.isArray(track) && track.length > 1) {
      return track[1].event_data.carousel_float_highlights;
    }

    // In case the track is not an array or has only one element,
    // then return null as it means there are no carousel float highlights
    return null;
  });

  // Remove any falsy values from the array
  return carouselTracks.filter(Boolean);
};

const getCarouselsFloatHighlightExperiments = (collections) => {
  const experiments = collections.map(
    (carousel) => carousel.properties.tracks?.experiments || {},
  );

  // Transform the array of experiments into a single object removing duplicates values
  return Object.assign({}, ...experiments);
};

/**
 * Get the melidata information for all the carousels in the appearance object
 * Check for the carousel_float_highlight tracks and experiments
 * @param {Object} appearance
 * @returns an object with the carousels tracks and experiments
 */
const getCarouselsMelidata = (appearance) => {
  const collections = getCollections(appearance);

  // If there is not carousels, return an empty object to not cause any error
  if (!collections.length) {
    return {};
  }

  const carouselsFloatHighlightTracks = getCarouselsFloatHighlightTracks(collections);
  const carouselsFloatHighlightExperiments = getCarouselsFloatHighlightExperiments(collections);

  // Only return the carouselsTracks/carouselExperiments objects if there are values
  return {
    carouselsTracks: carouselsFloatHighlightTracks.length
      ? {
        carousel_float_highlights: carouselsFloatHighlightTracks,
      }
      : {},
    carouselsExperiments: {
      ...carouselsFloatHighlightExperiments,
    },
  };
};

const getTracksTabs = (tab, selectedTab) => {
  if (tab) {
    const selectedTabTracks = tab[selectedTab].tracks;
    const modifiedTracks = {
      ...selectedTabTracks,
      melidata_track: {
        type: selectedTabTracks.melidata_track.type,
        event_data: {
          ...selectedTabTracks.melidata_track.event_data,
          node_position_l2: selectedTab,
          node_name_l2: tab[selectedTab].label,
        },
      },
    };
    return modifiedTracks;
  }
  return null;
};

module.exports = {
  eshopReportTracks,
  eshopShareTracks,
  sendTracksTabs,
  getTracksCcp,
  getTracksTabs,
  getRepurchaseTracks,
  getCarouselsMelidata,
};
