const { loadable } = require('nordic/lazy');

const RelevantItem = require('../../../adapters/withPolycard').default;
const { AffiliateFeeInfo, EmptyState, SideBar, BottomSheetIframe } = require('eshops-components-library').default;
const CarouselClips = require('@clips/carousel').default;
const EshopItem = require('@mshops-components-library/eshop-item').default;
const UnknownComponent = require('../../../appearance/unknownComponent').default;
/**
 * NORMAL LIST OF COMPONENTS
 */
const Document = require('../../../appearance/document').default;
const Layout = require('../../../appearance/layout').default;
const CategoryItem = require('../../../appearance/categoryItem');
const DynamicLayout = require('../../../components/dynamicLayout').default;
const BadgeCarousel = require('../../../appearance/badgeCarousel/component').default;
const ProfileHeader = require('../../../appearance/profileHeader/component').default;
const CategoryCarousel = require('../../../appearance/categoryCarousel/component').default;
const RecommendationCarousel = require('../../../appearance/recommendationCarousel/component').default;
const BottomSheetMenu = require('../../../appearance/bottomSheetMenu').default;
const MenuTab = require('../../../appearance/menuTab').default;
const Repurchase = require('../../../appearance/repurchase').default;
const LayoutWrapper = require('../../../appearance/layoutWrapper').default;
/**
 * ADAPTERS
 */
const withAddToCart = require('../../../adapters/withAddToCart').default;
const withHandleMenu = require('../../../adapters/withHandleMenu');
const withMarketplaceInfo = require('../../../adapters/withMarketplaceInfo');
const withAffiliatesMetrics = require('../../../adapters/withAffiliatesMetrics');
const withURLQueryParams = require('../../../adapters/withURLQueryParams');
const withFreeCarousel = require('../../../adapters/withFreeCarousel');
const withFeaturedCorridors = require('../../../adapters/withFeaturedCorridors').default;
const withBottomSheetContext = require('../../../adapters/withBottomSheetContext').default;

const Video = require('../../../appearance/videoPlayer').default;

/**
 * LOADABLE COMPONENTS
*/
const SnappedCollection = loadable(() => import('../../../appearance/snappedCollection'));
const Row = loadable(() => import('../../../appearance/row'));
const ContentList = loadable(() => import('../../../appearance/contentList'));
const SecondaryBanner = loadable(() => import('../../../appearance/secondaryBanner'));
const SectionText = loadable(() => import('../../../appearance/sectionText'));
const RichText = loadable(() => import('../../../appearance/richText'));
const DebugInfo = loadable(() => import('../../../appearance/debugInfo'));
const RelevantItemContainer = loadable(() => import('../../../appearance/relevantItemContainer'));
const BannersCarousel = loadable(() => import('../../../appearance/bannersCarousel'));
const CategoriesGallery = loadable(() => import('../../../appearance/categoriesGallery'));
const CategoryGallery = loadable(() => import('../../../appearance/categoriesGallery'));
const FreeShippingBar = loadable(() => import('../../../appearance/freeShippingBar/loadable'));
const Grid = loadable(() => import('../../../appearance/grid/loadable'));
const ValueProp = loadable(() => import('../../../appearance/valueProp/loadable'));
const CategorySuper = loadable(() => import('../../../appearance/categorySuper'));
const MerchSlider = loadable(() => import('../../../appearance/merchSlider/loadable'));
const AdnBanner = loadable(() => import('../../../appearance/adnBanner'), { ssr: false });
const ProductsGalleryMobile = loadable(() => import('../../../appearance/productsGalleryV2/mobile-loadable'));
const ProductsGalleryDesktop = loadable(() => import('../../../appearance/productsGalleryV2/desktop-loadable'));
const Slider = loadable(() => import('../../../appearance/slider'));
const ReportPage = loadable(() => import('../../../appearance/reportPage'));
const RecommendationContainer = loadable(() => import('../../../appearance/recommendationContainer'));
const RelevantGridContainer = loadable(() => import('../../../appearance/relevantGridContainer'));
const ProductsCarousel = loadable(() => import('../../../appearance/productsCarouselEshops'));
const PolycardGrid = loadable(() => import('../../../appearance/polycardGrid'));
const CouponNbt = loadable(() => import('../../../appearance/couponNbt'));
const CouponCarousel = loadable(() => import('../../../appearance/couponCarousel'));
const TapeValueProp = loadable(() => import('../../../appearance/tapeValueProp'));
const CategoryTree = loadable(() => import('../../../appearance/categoryTree'));

const nonEditableComponentList = {
  defaultComponent: UnknownComponent,
  /**
   * All the available components
   */
  nonEditables: {
    Document,
    Layout,
    Row,
    CategoriesGallery,
    BannersCarousel,
    CouponNbt,
    AffiliateFeeInfo,
    EmptyState,
    RelevantItem: withURLQueryParams(RelevantItem),
    RelevantItemContainer,
    RelevantGrid: withURLQueryParams(PolycardGrid),
    RelevantGridContainer,
    Slider,
    Carousel: BadgeCarousel,
    CategoryCarouselV2: withFreeCarousel(CategoryCarousel),
    CarouselClips,
    CategorySuper,
    ContentList,
    Collection: withFreeCarousel(SnappedCollection),
    DynamicCarousels: DynamicLayout,
    EshopItem: withAddToCart(EshopItem),
    TabbedCarousel: {
      mobile: ProductsCarousel,
      desktop: ProductsCarousel,
    },
    CollectionGrid: {
      displayAs: {
        original: {
          mobile: ProductsGalleryMobile,
          desktop: ProductsGalleryDesktop,
        },
        material: {
          mobile: ProductsGalleryMobile,
          desktop: ProductsGalleryDesktop,
        },
        default: {
          mobile: ProductsGalleryMobile,
          desktop: ProductsGalleryDesktop,
        },
      },
    },
    ProfileHeader,
    CategoryGallery: withMarketplaceInfo(CategoryGallery),
    FeaturedCorridors: withFeaturedCorridors(withMarketplaceInfo(CategoryGallery)),
    CategoryLabelImage: CategoryItem,
    SectionText,
    Video,
    DebugInfo,
    Grid,
    BannerSplinter: SecondaryBanner,
    MerchSlider,
    AdnBanner,
    ValueProp,
    FreeShippingBar,
    Navigation: withHandleMenu(SideBar),
    RecommendationCarousel: withAffiliatesMetrics(RecommendationCarousel),
    RichText,
    RecommendationContainer,
    ReportPage,
    BottomSheetIframe: withBottomSheetContext(BottomSheetIframe),
    BottomSheetMenu,
    MenuTab,
    CouponCarousel,
    LayoutWrapper,
    Repurchase,
    TapeValueProp,
    CategoryTree,
  },
  /**
   * Components with an editable version
   */
  editables: {},
};

/**
 * EXPORTS
 */
module.exports = nonEditableComponentList;
