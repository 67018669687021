/* eslint-disable arrow-body-style */
const React = require('react');
const { useRenderContext } = require('../pages/home/context');
const { useCartContext } = require('../pages/marketplaceHome/context/cart');

const { WITHOUT_ACTIONS_IN_ESHOPSITEM } = require('../utils/constants/shop/types');

const withFreeCarousel = (WrappedComponent) => {
  const FreeCarouselData = (props) => {
    const { shopType } = useRenderContext();
    const seeElements = {
      action_card: !WITHOUT_ACTIONS_IN_ESHOPSITEM.includes(shopType),
    };
    const { marketplaceInfo: { userZipCode } } = useCartContext();

    return (
      <WrappedComponent
        {...props}
        useChevron={false}
        seeElements={seeElements}
        isFreeCarousel
        userZipCode={userZipCode}
      />
    );
  };
  return FreeCarouselData;
};

module.exports = withFreeCarousel;
