import React from 'react';
import withBottomSheetContext from '../../adapters/withBottomSheetContext';
import withFollowerData from '../../adapters/withFollowerData';

const { useRenderContext } = require('../../pages/home/context');
const { ProfileHeader } = require('eshops-components-library').default;

const ProfileHeaderComponent = (props) => {
  const { theme, shopModel } = useRenderContext();

  const trackingData = {
    shopType: shopModel?.storefront?.type,
    ownerId: props?.ownerId?.toString() || '',
    buyerId: props?.buyerId?.toString() || '',
    officialStoreId: parseInt(shopModel?.storefront?.filters?.official_store, 10) || -1,
    shopName: shopModel?.storefront?.name || shopModel?.shop?.name,
    trackingId: shopModel?.appearance?.properties?.metrics?.tracking_id || 'NONE',
    shopId: shopModel?.storefront?.id || shopModel?.shop?.id,
  };

  return <ProfileHeader {...props} trackingData={trackingData} theme={theme} />;
};
export default withBottomSheetContext(withFollowerData(ProfileHeaderComponent));
