/* eslint-disable react/no-danger, import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player/lazy';
import { Section } from '@mshops-components-library/common';
import Clickable from '@mshops-web-components/clickable';

import { validateVideoUrl } from './utils';
import { MODIFIERS, EXPLORER_THEME } from './constants';

import PlayIconVideo from './PlayIcon';
import getVideoAndTextClassNames from './classHelper';
import VideoPreview from './VideoPreview';

const VideoPlayer = ({
  alignment,
  title,
  text,
  variant,
  video_link: videoLink,
  layoutTheme,
  lazyload = 'on',
  isEshops = false,
  controls = true,
  withTranparency = true,
  deviceType,
  i18n,
}) => {
  const haveImage = videoLink?.image !== '';
  const isMobile = deviceType === 'mobile';
  const showInitialBackground = !isMobile && !haveImage;
  const isVariantV2 = variant === 'v2';

  const [isLoadReactPlayer, setLoadReactPlayer] = useState(false);
  const [showVideo, setShowVideo] = useState(!haveImage);
  const [showImageVideo, setShowImageVideo] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [urlVideo, setUrlVideo] = useState(videoLink?.link);
  const [showStaticBackground, setShowStaticBackground] = useState(showInitialBackground);

  const refPlayer = useRef(null);

  const {
    sectionClassName,
    sectionVideo,
    showPlayer,
    showYoutube,
  } = getVideoAndTextClassNames({ variant, showVideo, alignment });

  const onImageClick = () => {
    if (!videoLink.link) { return; }
    setShowVideo(true);
    if (!isMobile) {
      setShowStaticBackground(true);
    }
  };

  useEffect(() => {
    setLoadReactPlayer(true);
  }, []);

  useEffect(() => {
    if (haveImage) {
      setShowVideo(false);
      return;
    }
    if (validateVideoUrl(videoLink?.link) !== MODIFIERS.ERROR || videoLink.link) {
      setShowVideo(true);
    }
  }, [videoLink]);

  useEffect(() => {
    if (!withTranparency) {
      setShowImageVideo(!playing);
    }
    if (playing) {
      setShowStaticBackground(true);
    }
  }, [playing, withTranparency]);

  useEffect(() => {
    if (validateVideoUrl(videoLink?.link) === MODIFIERS.ERROR || !videoLink.link) {
      setShowVideo(false);
      setUrlVideo('');
    } else {
      setUrlVideo(videoLink?.link);
    }
  }, [videoLink.link]);

  const onPlayInPreview = () => {
    if (!videoLink.link) { return; }
    setShowImageVideo(false);
    if (refPlayer.current) {
      refPlayer.current.seekTo(0, 'seconds');
    }
    setShowStaticBackground(true);
    setPlaying(() => !playing);
  };

  const handleEnded = () => {
    setShowStaticBackground(false);
    if (videoLink?.image === '') {
      refPlayer.current.showPreview();
    } else {
      setShowImageVideo(true);
    }
  };
  const isUrlvideEmpty = urlVideo === '';
  const videoAndTextContainerClass = classNames('video-and-text video-and-text__container', {
    'video-and-text__container--v2-playing': isEshops && isVariantV2 && showVideo,
  });

  const isExplorerAndV2 = layoutTheme === EXPLORER_THEME && isVariantV2;

  const videoContentClass = classNames('video-and-text__image--center', {
    'ui-ms-overlay-video-image--show': showImageVideo || isUrlvideEmpty,
  });

  const imageVideoClass = classNames('ui-ms-overlay-video-image', {
    'ui-ms-overlay-video-image--initial': isUrlvideEmpty,
  });

  const backgroundStaticClass = classNames(imageVideoClass, {
    'ui-ms-overlay-static': showStaticBackground,
  });

  const videoBackgroundClass = classNames(showPlayer, {
    'ui-ms-background-transparent': withTranparency,
  });

  return (
    <Section className={sectionClassName}>
      <div className="row container">
        <div className="video-and-text">
          <div
            style={{
              backgroundImage: `url(${isEshops && isVariantV2 ? videoLink.image : ''})`,
            }}
            className={videoAndTextContainerClass}
          >
            <div className={sectionVideo}>
              <h2 className="video-and-text__container-title">
                {!title.hidden && title.text}
              </h2>
              {!text.hidden && (
                <p className="video-and-text__container-description">
                  {text.text}
                </p>
              )}
              {isExplorerAndV2 && <PlayIconVideo onImageClick={onImageClick} theme={layoutTheme} i18n={i18n} />}
            </div>
            <div className="video-and-text__col video-and-text__container-video">
              <div className={videoContentClass}>
                {
                  isLoadReactPlayer && urlVideo !== '' && (
                    <ReactPlayer
                      ref={refPlayer}
                      url={urlVideo}
                      className={showYoutube}
                      width="100%"
                      height="100%"
                      controls={controls}
                      muted={false}
                      playing={playing}
                      light={!showVideo
                        ? (
                          <VideoPreview
                            className={showPlayer}
                            image={videoLink.image}
                            title={title}
                            lazyload={lazyload}
                            layoutTheme={layoutTheme}
                            onClik={onImageClick}
                            isExplorerAndV2={isExplorerAndV2}
                            i18n={i18n}
                          />
                        ) : false}
                      config={{
                        youtube: {
                          playerVars: {
                            origin: window.location.origin,
                            showinfo: 0,
                            rel: 0,
                            fs: 0,
                            autoplay: haveImage ? 1 : 0,
                          },
                        },
                      }}
                      iframeAttributes={{
                        allow: 'autoplay; encrypted-media; picture-in-picture',
                      }}
                      onPause={() => setPlaying(false)}
                      onEnded={handleEnded}
                      onPlay={() => setPlaying(true)}
                      onClickPreview={onImageClick}
                    />
                  )
                }
                {
                  withTranparency && showStaticBackground && !showVideo ? (
                    <div className={backgroundStaticClass}>
                      <Clickable className={videoBackgroundClass} onClick={onPlayInPreview} />
                    </div>
                  ) : (
                    <div className={imageVideoClass}>
                      <VideoPreview
                        className={showPlayer}
                        image={videoLink.image}
                        title={title}
                        lazyload={lazyload}
                        layoutTheme={layoutTheme}
                        onClik={onPlayInPreview}
                        isExplorerAndV2={isExplorerAndV2}
                        i18n={i18n}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

VideoPlayer.propTypes = {
  alignment: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  video_link: PropTypes.shape({
    image: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
  }).isRequired,
  layoutTheme: PropTypes.string.isRequired,
  lazyload: PropTypes.string,
  isEshops: PropTypes.bool,
  controls: PropTypes.bool,
  withTranparency: PropTypes.bool,
  deviceType: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

export default VideoPlayer;
