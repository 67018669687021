import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ModalAddToCart from '@mshops-components-library/modal-add-to-cart';

import { useRenderContext } from '../pages/home/context';
import { useCartContext, useCartDispatcher } from '../pages/marketplaceHome/context/cart';
import { useFreeShippingDispatcher } from '../pages/marketplaceHome/context/freeShipping';
import {
  addFragment,
  dispatchAddToCardShopsInfo,
  getFormattedTrackingData,
  getTracks,
  sendAddTrack,
  sendRemoveTrack,
} from '../utils/addToCartTracks';
import updateCartFreya from '../utils/updateCartFreya';
import { useSnackbar } from '../appearance/snackbar/context/useSnackbar';
import { SUPER } from '../utils/constants/sections';

function withAddToCart(WrappedComponent) {
  const AddToCart = (props) => {
    const SHOPS_TYPE = 'mshops';
    const MSHOPS_CHO_APP_DISABLED = 'mshops-cho-app-disabled';
    const STOCK_GEOLOCATED_FLAG = 'ft-geolocated-stock';
    const cartActions = useCartDispatcher();
    const freeShippingActions = useFreeShippingDispatcher();
    const { addedItems, marketplaceInfo: { isWebview, userZipCode, shopType: marketplaceShopType } } = useCartContext();
    const shopType = marketplaceShopType || SHOPS_TYPE;
    const { osName, device, appearance, features, apiBasePath } = useRenderContext();
    const snackbar = useSnackbar();
    const onlyWebCheckout = shopType === SHOPS_TYPE && features?.includes(MSHOPS_CHO_APP_DISABLED);
    const [UrlAddToCartModal, setUrlAddToCartModal] = useState(null);
    const { item, cartErrorSnackbarLabel: errorSnack = '', id: shopsId, modalTitle, i18n } = props;
    const itemId = item ? item.id : shopsId;
    const userProductId = item ? item.user_product_id : null;
    const availableQuantity = item ? item.available_quantity : null;
    const geolocatedStockFlagEnabled = features?.includes(STOCK_GEOLOCATED_FLAG);

    const cartErrorSnackbarLabel = (errorSnack && errorSnack?.length > 0)
      ? errorSnack
      : i18n.gettext('Algo salió mal. Inténtalo de nuevo.');

    const foundItem = addedItems?.find(cartItem => cartItem.id === itemId);

    useEffect(() => {
      if (shopType !== SUPER || !geolocatedStockFlagEnabled) return;
      const updateStock = async () => {
        if (foundItem && !foundItem.geolocated_quantity) {
          await cartActions.updateItemStock(
            foundItem.id, userProductId, userZipCode, shopType, availableQuantity,
          );
        }
      };

      updateStock();
    }, [foundItem]);

    const showErrorMessage = (message = cartErrorSnackbarLabel) => {
      snackbar({
        message,
      });
    };

    const updateCartUI = () => {
      if (shopType === SHOPS_TYPE) {
        return cartActions.getCartItems(shopType);
      }

      freeShippingActions.updateFreeShippingBar(userZipCode, shopType);
      return updateCartFreya(isWebview, apiBasePath);
    };

    const deleteItem = async (id, quantity, variation) => {
      try {
        await cartActions.deleteCartItem(
          id, variation, userZipCode, shopType,
        );
        const tracks = getTracks(appearance, itemId);

        sendRemoveTrack(tracks, { quantity });
        updateCartUI();
        return true;
      } catch (error) {
        if (error?.response?.status === 401) {
          window.location.assign(error.response.data.login);
          return false;
        }
        // TODO: we do this to avoid show snackbar while prop is empty, delete once backend begins to send this
        showErrorMessage();

        return false;
      }
    };

    const updateItem = async (id, quantity, variation) => {
      try {
        await cartActions.updateCartItem(
          id, quantity, variation, userZipCode, shopType,
        );
        const tracks = getTracks(appearance, itemId);

        if (quantity > foundItem?.quantity) {
          sendAddTrack(tracks, { quantity });
        } else if (quantity < foundItem?.quantity) {
          sendRemoveTrack(tracks, { quantity });
        }

        updateCartUI();
        return true;
      } catch (error) {
        if (error?.response?.status === 401) {
          window.location.assign(error.response.data.login);
          return false;
        }
        // TODO: we do this to avoid show snackbar while prop is empty, delete once backend begins to send this
        showErrorMessage();

        return false;
      }
    };

    const addItem = async (id, quantity, variation) => {
      try {
        let currentGeolocatedQuantity = availableQuantity;
        if (!foundItem?.geolocated_quantity && geolocatedStockFlagEnabled) {
          const geolocatedQuantity = await cartActions.updateItemStock(
            id, userProductId, userZipCode, shopType, availableQuantity,
          );
          currentGeolocatedQuantity = geolocatedQuantity;
        }

        let quantityToAdd = quantity;
        if (currentGeolocatedQuantity < quantity) {
          quantityToAdd = currentGeolocatedQuantity;
        }

        const { data } = await cartActions.addToCart(
          id, quantityToAdd, variation, userZipCode, shopType,
        );

        if (data?.message === 'cart.list_is_full') {
          showErrorMessage(i18n.gettext(
            'Alcanzaste el máximo de productos. Puedes comprar tu carrito '
            + 'o hacer lugar para agregar más.',
          ));
          deleteItem(id, quantityToAdd, variation);
          return false;
        }

        if (data?.url) {
          const promotions = props?.componentId === 'tabbed-carousel_promo';
          addFragment(`item_id=MLA1121797129&component=tabbed_carrousel&page_from=home&custom_categories=false&promotions=${promotions.toString()}`);
          setUrlAddToCartModal(data.url);
        }

        const tracks = getTracks(appearance, itemId);

        if (window?.tracking_mediator && shopType === SHOPS_TYPE) {
          const shopsTrackingData = getFormattedTrackingData(
            {
              currency: item?.price?.currency_id,
              price: item?.price?.value,
              title: item?.title,
              item_id: id,
              brand: item?.brand_info?.name || '',
              name: item?.title,
              quantity: quantityToAdd,
            },
            variation,
          );

          dispatchAddToCardShopsInfo(window.tracking_mediator, shopsTrackingData);
        }

        sendAddTrack(tracks, { quantity: quantityToAdd });
        updateCartUI();
        return true;
      } catch (err) {
        if (err?.response?.status === 401) {
          window.location.assign(err.response.data.login);
          return false;
        }

        // TODO: we do this to avoid show snackbar while prop is empty, delete once backend begins to send this
        showErrorMessage(
          err?.response?.data?.error === 'cart.list_is_full'
            ? i18n.gettext(
              'Alcanzaste el máximo de productos. Puedes comprar tu carrito '
              + 'o hacer lugar para agregar más.',
            )
            : cartErrorSnackbarLabel,
        );

        return false;
      }
    };

    return (
      <>
        <ModalAddToCart
          id=""
          title={modalTitle}
          src={UrlAddToCartModal}
          onCloseModal={() => setUrlAddToCartModal(null)}
          deviceType={device}
          show={!!UrlAddToCartModal}
        />
        <WrappedComponent
          {...props}
          eshopType={shopType}
          addItem={addItem}
          updateItem={updateItem}
          deleteItem={deleteItem}
          cartItemQuantity={foundItem ? foundItem.quantity : 0}
          geolocatedQuantity={foundItem?.geolocated_quantity ? foundItem.geolocated_quantity : availableQuantity}
          osName={osName}
          deviceType={device}
          userZipCode={userZipCode}
          onlyWebCheckout={onlyWebCheckout}
        />
      </>
    );
  };

  AddToCart.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    id: PropTypes.string,
    cartErrorSnackbarLabel: PropTypes.string,
  };

  return AddToCart;
}

export default withAddToCart;
